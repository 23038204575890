
import { useState, useEffect } from "react";

// routing
import { useParams } from "react-router-dom";

// styles
import "./Satistics.scss";

// components
import StudentPerformancePlot from "../../../../../../components/Graph/StudentPerformancePlot/StudentPerformancePlot";
import List from "../../../../../../components/List";

// services
import networking from "../../../../../../services/handleNetworking/networking";
import reportApi from "../../../../../../services/Networking/report/report";
import { processQuartilePercentageData, processQuartilePointsData, processQuartilePointsForQuestions, processStdData, processStdDataForScatterPlot } from "./services/processData";

// constants
import { SUCCESS } from "../../../../../../services/errors/constants";
import { AVERAGE_GRAPH, KWARTILE_GRAPH } from "./constants";
import QuestionsStatistics from "./components/QuestionsStatistics/QuestionsStatistics";

const Satistics = (props) => {

    // routing
    let { assignmentID, examID } = useParams();

    // states
    const [heighestPoints, updateHeighestPoints] = useState(100);
    const [showPoints, toggleShowPoints] = useState(false);
    const [graphToShow, toggleGraphToShow] = useState("average");

    // data
    const [scatterData, updateScatterData] = useState(null);
    const [graphData, updateGraphData] = useState(null);
    const [quartilePointsQuestions, updateQuartilePointsQuestions] = useState(null);

    const [stdPercentage, updateStdPercentage] = useState(null);
    const [medianPercentage, updateMedianPercentage] = useState(null);
    const [medianPoints, updateMedianPoints] = useState(null);

    const updateGraph = (graphKey) => {
        if (graphKey !== graphToShow) {
            if (graphKey === AVERAGE_GRAPH) {
                toggleShowPoints(false);
            }
            toggleGraphToShow(graphKey);
        }

        if (graphKey === AVERAGE_GRAPH) {
            getStdPercentage();
        } else if (graphKey === KWARTILE_GRAPH && !showPoints) {
            getMedianPercentage();
        } else if (graphKey === KWARTILE_GRAPH && showPoints) {
            getMedianPoints();
        }
    }
    const updateShowPoints = (newShowPoints) => {
        toggleShowPoints(newShowPoints);
        
        if (graphToShow === AVERAGE_GRAPH) {
            getStdPercentage();
        } else if (graphToShow === KWARTILE_GRAPH && !newShowPoints) {
            getMedianPercentage();
        } else if (graphToShow === KWARTILE_GRAPH && newShowPoints) {
            getMedianPoints();
        }
    }
    
    useEffect(() => {
        if (assignmentID && examID) {
            getStdPercentage();
            getMedianPoints(true);
        }
    }, []);

    const getStdPercentage = async() => {
        let newGraphData = stdPercentage;
        if (!newGraphData) {
            const returned = await networking({
                updateContent: updateStdPercentage,
                errorOnSuccess: false,
    
                api: reportApi.getAssignmentItemSTDReports,
                apiParams: {
                    assignmentId: assignmentID,
                    itemId: examID
                },
    
                // updateError: updateGetExamError,
            });
    
            if (returned?.status === SUCCESS) {
                newGraphData = returned.payload;
            } else {
                return;
            }
        }

        const scatterData = processStdDataForScatterPlot(newGraphData, "studentPercentages");
        const avgData = processStdData(newGraphData);
        updateScatterData(scatterData);
        updateGraphData(avgData);
        updateHeighestPoints(100);
    }
    const getMedianPoints = async(dontUpdateGraph) => {
        let newGraphData = medianPoints;
        if (!newGraphData) {
            const returned = await networking({
                updateContent: updateMedianPoints,
                errorOnSuccess: false,
    
                api: reportApi.getAssignmentItemMedianPointsReports,
                apiParams: {
                    assignmentId: assignmentID,
                    itemId: examID
                },
    
                // updateError: updateGetExamError,
            });
    
            if (returned?.status === SUCCESS) {
                newGraphData = returned.payload;
            } else {
                return;
            }
        }

        const scatterData = processStdDataForScatterPlot(newGraphData, "studentPoints");
        const { kwartileData, heighestPoints } = processQuartilePointsData(newGraphData);
        const processedQuestions = processQuartilePointsForQuestions(newGraphData);
        updateQuartilePointsQuestions(processedQuestions);
        if (!dontUpdateGraph) {
            updateScatterData(scatterData);
            updateGraphData(kwartileData);
            updateHeighestPoints(heighestPoints);
        }
    }

    const getMedianPercentage = async() => {
        let newGraphData = medianPercentage;
        if (!newGraphData) {
            const returned = await networking({
                updateContent: updateMedianPercentage,
                errorOnSuccess: false,
    
                api: reportApi.getAssignmentItemMedianPercentageReports,
                apiParams: {
                    assignmentId: assignmentID,
                    itemId: examID
                },
    
                // updateError: updateGetExamError,
            });
    
            if (returned?.status === SUCCESS) {
                newGraphData = returned.payload;
            } else {
                return;
            }
        }
        const scatterData = processStdDataForScatterPlot(newGraphData, "studentPercentages");
        const kwartileData = processQuartilePercentageData(newGraphData);
        updateScatterData(scatterData);
        updateGraphData(kwartileData);
        updateHeighestPoints(100);
    }

    const renderBar = (index, data) => {
        // console.log('data', data);

        return (
            <div>

            </div>
        )
    }

    return (
        <>
            <div className="assignedExamStatisticsView" >
                <StudentPerformancePlot
                    title="Studentenprestaties Overzicht"

                    showPoints={showPoints}
                    toggleShowPoints={updateShowPoints}
                    graphToShow={graphToShow}
                    updateGraph={updateGraph}

                    keys={{
                        [AVERAGE_GRAPH]: {
                            middleLine: "avgPercentage",
                            range: "std",
                            errorBarY: "errorBarY",
                            max: "maxPoints",
                            missedPoints: "missedPoints"
                        },
                        [KWARTILE_GRAPH]: {
                            middleLine: "median",
                            range: "kwartiles",
                            errorBarY: "errorBarY",
                            max: "maxPoints",
                            missedPoints: "missedPoints"
                        }
                    }}

                    scatterData={scatterData}
                    data={graphData}

                    domainX={[1, graphData?.length]}
                    domainY={[0, heighestPoints]}
                />
                <List
                    items={medianPoints}
                    renderItem={renderBar}
                />
            </div>

            <QuestionsStatistics
                quartilePointsQuestions={quartilePointsQuestions}
            />

        </>
    )
}

export default Satistics;
