
import { useState, useEffect } from "react";

// routing
import { useParams, useHistory, useLocation } from 'react-router-dom';

// redux
import { useDispatch, useSelector } from 'react-redux';

// styles
import "./TakeAssignedExam.scss";
import "../../assets/examStyles.scss";

// networking
import assignmentUserApi from "../../../../services/Networking/AssignmentUser/AssignmentUser";
import assignmentApi from "../../../../services/Networking/assignment/assignment";
import examsApi from "../../../../services/Networking/exams/exams";
import examEvaluation from "../../../../services/Networking/examEvaluation/examEvaluation";

// classes
import ClientData from "../../../../services/classes/clientData/ClientData";
import CustomErrorsManager from "../../../../services/errors/classes/CustomErrorsManager";
import StickyButtonClass from "../../components/StickyButtons/StickyButtonClass";
import errorClass from "../../../../services/errors/classes";

// services
import networking from "../../../../services/handleNetworking/networking";
import sendableUserAnswers from "../../services/sendableUserAnswers";
import allAnswerFilled from "../TakeExam/services/allAnswerFilled";
import handleEnvironment from "../../../../services/handleEnvironment/handleEnvironment";
import deadlinePassed from "../../services/deadlinePassed/deadlinePassed";
import authApi from "../../../../services/Networking/authentication/authentication";
import handleLocalStorage from "../../../../services/localStorage";

// components
import ExamInfoAssignedExam from "../../components/ExamInfo/ExamInfoAssignedExam";
import UnsavedExamChanges from "../../components/UnsavedExamChanges/UnsavedExamChanges";
import Title from "../../components/Title";
import Loader from "../../../../components/Loader";
import Redux from "../../../../components/Redux/Redux";
import List from "../../../../components/List";
import Question from "../../components/Question";
import initQuestionHubs from "../../services/initQuestionHubs";
import StickyButtons from "../../components/StickyButtons";
import LocalModal from "../../../../components/LocalModal";
import SetUserSettings from "../../components/SetUserSettings/SetUserSettings";
import GenerateFeedbackLoading from "../../components/GenerateFeedbackLoading/GenerateFeedbackLoading";

// constants
import { REDUX_ERRORS } from "../../../../components/Redux/services/constants";
import { MAKE } from "../../services/tabConstants";
import { SAVE, SUBMIT } from "../TakeExam/constants/constants";
import { INTERNAL_ERROR, SUCCESS, UNAUTHORIZED } from "../../../../services/errors/constants";
import { PUBLISHED } from "../../../../services/constants/stateConstants";
import { ETALIA_ENV, UVA_ENV } from "../../../../services/handleEnvironment/constants";
import { ALLOW_GENERATED_FEEDBACK_KEY } from "../../../MyProfile/components/Settings/utils/constants";
import { ADD_LOCAL_STORAGE, REMOVE_LOCAL_STORAGE } from "../../../../services/localStorage/constants";
import { LAST_ERROR_GENERATED_FEEDBACK } from "../../services/constants/feedbackGeneration";

const TakeAssignedExam = (props) => {

    // redux
    const dispatch = useDispatch();
    const user = useSelector(state => state.userReducer);

    // routing
    let history = useHistory();
    let location = useLocation();
    let { assignmentID, examID } = useParams();

    // data
    const [exam, updateExam] = useState(null);
    const [assignment, updateAssignment] = useState(null);
    const [newlySavedExamOrder, updateNewlySavedExamOrder] = useState();
    const [userSettings, updateUserSettings] = useState(null);

    // states
    const [showHandInAlert, toggleShowHandInAlert] = useState(0);
    const [getAssignmentLoading, toggleGetAssignmentLoading] = useState(false);

    const [showUserSettings, toggleShowUserSettings] = useState(false);
    const [showUserSettingsBeforeSubmission, toggleShowUserSettingsBeforeSubmission] = useState(false);

    const [showExamInfo, toggleShowExamInfo] = useState(true);
    const [showQuestionInfo, toggleShowQuestionInfo] = useState(true);

    const [showUnsavedWarning, toggleShowUnsavedWarning] = useState(false);
    const [showLoadingGenerateFeedback, toggleShowLoadingGenerateFeedback] = useState(false);

    // loading
    const [loading, toggleLoading] = useState(true);
    const [loadingSaving, toggleLoadingSaving] = useState(false);
    const [loadingSubmitting, toggleLoadingSubmitting] = useState(false);
    const [loadingUserSettings, toggleLoadingUserSettings] = useState(true);

    // errors
    const [getExamError, updateGetExamError] = useState(null);
    const [postExamError, updatePostExamError] = useState(null);
    const [getAssignmentError, updateGetAssignmentError] = useState(null);
    const [generateFeedbackError, updateGenerateFeedbackError] = useState(null);

    const formatExam = (fetchedExam) => {

        const formattedQuestions = initQuestionHubs({ sentQuestions: fetchedExam?.questions });

        const formattedExam = new ClientData({
            id: fetchedExam?.examUUID,
            sent: fetchedExam,
        });

        // formatQuestions(formattedExam);

        updateExam(
            new ClientData({
                id: fetchedExam?.examUUID,
                childLists: {
                    feedback: [],
                    questions: formattedQuestions,
                },
                sent: fetchedExam,
            })
        );
    }
    const getExam = async() => {
        const returned = await networking({
            updateContent: formatExam,
            toggleLoading: toggleLoading,
            errorOnSuccess: false,

            api: examsApi.getExamByExamId,
            apiParams: { examId: examID, state: PUBLISHED },

            updateError: updateGetExamError,
        });
    }

    const getAssignment = async() => {
        await networking({
            updateContent: updateAssignment,
            toggleLoading: toggleGetAssignmentLoading,
            errorOnSuccess: false,

            api: assignmentApi.getAssignment,
            apiParams: assignmentID,

            updateError: updateGetAssignmentError,
        });
    }

    const fetchUserSettings = async() => {
        const response = await networking({
            toggleLoading: toggleLoadingUserSettings,

            api: authApi.getUserSettings,
            apiParams: user?.user?.userId,

            updateContent: updateUserSettings,
        });
        if (response && response.status === SUCCESS) {
            return response.payload;
        }
        return null;
    }

    useEffect(() => {
        getExam();
        getAssignment();
        fetchUserSettings();
    }, []);

    const renderQuestion = (i, questionHub) => {

        return (
            <Question
                key={questionHub.questionId}

                examView={MAKE}
                handedIn={showHandInAlert}

                questionHub={questionHub}

                showQuestionInfo={showQuestionInfo}
                toggleShowQuestionInfo={toggleShowQuestionInfo}
            />
        )
    }

    const sendUserAnswers = async(state, currentUserSettings) => {

        const toSend = sendableUserAnswers(exam?.childLists.questions, true);

        const returned = await networking({
            toggleLoading: state === SUBMIT ? toggleLoadingSubmitting : toggleLoadingSaving,
            errorOnSuccess: true,

            api: assignmentUserApi.postAssignedExamByUser,
            apiParams: { assignmentUuid: assignmentID, itemUuid: examID, state: state, userAnswers: toSend },

            updateError: updatePostExamError,
            customErrors: (errorCode) => new CustomErrorsManager({
                [SUCCESS]: {
                    title: "Uw aanpassingen zijn opgeslagen"
                },
                [UNAUTHORIZED]: {
                    title: deadlinePassed(new Date(assignment?.submittingDeadline)) ? "Deadline verstreken" : "Geen toegang"
                }
            }).getError(errorCode)
        });

        if (returned && returned.status === SUCCESS && state === SUBMIT) {
            updateNewlySavedExamOrder(returned?.payload);
            if (currentUserSettings?.[ALLOW_GENERATED_FEEDBACK_KEY] === true && assignment?.canAIGrade === true) {
                toggleShowLoadingGenerateFeedback(true);
                await feedbackGeneren(returned?.payload);
            }
            goToNextPage();
        }
    }

    const feedbackGeneren = async(order) => {
        handleLocalStorage({
            action: REMOVE_LOCAL_STORAGE,
            key: LAST_ERROR_GENERATED_FEEDBACK,
        });
        if (handleEnvironment() === ETALIA_ENV) {
            return;
        }

        const feedback = await examEvaluation.postExamEvaluation(examID, order);
        if (!feedback || feedback.status !== SUCCESS) {
            handleLocalStorage({
                action: ADD_LOCAL_STORAGE,
                key: LAST_ERROR_GENERATED_FEEDBACK,
                data: new Date()
            });
            updateGenerateFeedbackError(new errorClass({
                errorCode: INTERNAL_ERROR,
                description: "Feedback genereren niet gelukt. Het kan zijn dat er te veel studenten tegelijkertijd feedback aan het genereren zijn. Probeer het zo opnieuw."
            }));
        }
        return feedback;
    }

    const goToNextPage = () => {
        if (assignment.canViewFeedback) {
            history.push(`/opdrachten/${assignmentID}/tentamen/${examID}/Inkijken`);
        } else {
            history.push(`/opdrachten/${assignmentID}`);
        }
    }

    const initSubmitExam = (latestUserSettings) => {
        if (!latestUserSettings && handleEnvironment() === UVA_ENV) {
            toggleShowUserSettingsBeforeSubmission(true);
            return;
        }

        // check if all answers filled
        const skippedQuestion = allAnswerFilled(exam?.childLists.questions);

        if (skippedQuestion) {
            // false +1 is 1 in JS, so it aint pretty but it works with one var
            toggleShowHandInAlert(value => value + 1);
            toggleLoadingSubmitting(false);
        } else {
            sendUserAnswers(SUBMIT, latestUserSettings);
        }
    }

    const saveExam = () => {
        toggleLoadingSaving(true);
        sendUserAnswers(SAVE);
    }

    const close = (forceClose) => {

        // TODO: not functional until student answer is implemented inside student answer

        // // check if unsaved changes
        // if (!forceClose && examChanged({
        //     exam: exam,

        //     examState: exam.getCurrentValue("state"),
        //     examId: examID,
        //     questionHubs: exam?.childLists?.questions
        // })) {
        //     toggleShowUnsavedWarning(true);
        //     return;
        // }

        history.goBack();
    }

    return (
        <div
            className="examViewUniversal"
        >{
            loading && !exam ? <Loader /> :
            !exam ? "Geen tentamen geselecteerd" :
            <>

                <StickyButtons
                    buttons={[
                        loadingSaving ? new StickyButtonClass({

                            title: <Loader />,
                            onClickFunc: null,
                            link: null,
                    
                        }) : new StickyButtonClass({

                            title: "Opslaan",
                            onClickFunc: saveExam,
                            link: null,
                    
                        }),
                        loadingSubmitting ? new StickyButtonClass({

                            title: <Loader />,
                            onClickFunc: null,
                            link: null,
                    
                        }) : new StickyButtonClass({

                            title: "Inleveren",
                            onClickFunc: () => initSubmitExam(userSettings),
                            link: null,
                    
                        }),
                        new StickyButtonClass({

                            title: "Sluiten",
                            onClickFunc: close,
                            link: null,
                    
                        })
                    ]}
                />

                <Title
                    title={exam?.getCurrentValue("title")}

                    showExamInfo={showExamInfo}
                    toggleShowExamInfo={toggleShowExamInfo}

                    loading={loading || getAssignmentLoading}
                />

                {
                    showExamInfo ?
                    <>
                        <ExamInfoAssignedExam
                            exam={exam}
                            assignment={assignment}
                            assignmentUuid={assignmentID}
                            userSettings={userSettings}
                            loadingUserSettings={loadingUserSettings}
                            toggleShowUserSettings={toggleShowUserSettings}

                            view={MAKE}
                        />
                    </> : null
                }

                {
                    !exam || !exam?.childLists?.questions ? null :
                    exam?.childLists?.questions?.length === 0 ?
                    <div className="examNoQuestionsUniversal" >Op dit moment heeft dit tentamen geen vragen.</div> :
                    <List
                        items={exam?.childLists?.questions}
                        renderItem={renderQuestion}
                    />
                }

                <UnsavedExamChanges
                    showUnsavedWarning={showUnsavedWarning}
                    toggleShowUnsavedWarning={toggleShowUnsavedWarning}
    
                    close={close}
                />

                <LocalModal
                    show={showUserSettings || showUserSettingsBeforeSubmission}
                    toggleShow={showUserSettings ? toggleShowUserSettings : toggleShowUserSettingsBeforeSubmission}

                    component={
                        <SetUserSettings
                            userSettings={userSettings}
                            updateUserSettings={updateUserSettings}
                            fetchUserSettings={fetchUserSettings}
                            submitExam={showUserSettings ? null : initSubmitExam}
                            toggleShow={showUserSettings ? toggleShowUserSettings : toggleShowUserSettingsBeforeSubmission}
                        />
                    }
                />

                <LocalModal
                    show={showLoadingGenerateFeedback}
                    toggleShow={() => null}

                    component={
                        <GenerateFeedbackLoading />
                    }
                />

                <Redux
                    showSuccess={false}
                    varId={REDUX_ERRORS}

                    reduxVar={getExamError}
                />
                <Redux
                    showSuccess={false}
                    varId={REDUX_ERRORS}

                    reduxVar={postExamError}
                />
                <Redux
                    showSuccess={false}
                    varId={REDUX_ERRORS}
                    
                    reduxVar={getAssignmentError}
                />
                <Redux
                    showSuccess={false}
                    varId={REDUX_ERRORS}

                    reduxVar={generateFeedbackError}
                />
            </>
        }</div>
    )
}

export default TakeAssignedExam;
