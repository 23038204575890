
export const processStdDataForScatterPlot = (sentData, itemsKey) => {

    const scatterData = [];

    let universalMaxCount = 0;
    sentData.forEach((data) => {
        const counts = data[itemsKey].reduce((acc, item) => {
            acc.set(item, (acc.get(item) || 0) + 1);
            return acc;
        }, new Map());
        const maxCount = Math.max(...counts.values());
        universalMaxCount = Math.max(universalMaxCount, maxCount);
    });

    sentData.forEach((data, index) => {
        const questionNumber = index + 1;

        // Count occurrences of each unique value
        const counts = data[itemsKey].reduce((acc, item) => {
            acc.set(item, (acc.get(item) || 0) + 1);
            return acc;
        }, new Map());

        // Normalize if the max count exceeds 5
        const normalizedCounts = new Map();
        if (universalMaxCount > 5) {
            counts.forEach((count, value) => {
                const normalizedValue = (count / universalMaxCount) * 5; // Scale to max 5
                normalizedCounts.set(value, Math.round(normalizedValue)); // Optional rounding
            });
        } else {
            counts.forEach((count, value) => {
                normalizedCounts.set(value, count); // Keep original counts
            });
        }

        // Create scatter plot data
        const isFirstQuestion = index === 0;
        const isLastQuestion = index === sentData.length - 1;
        normalizedCounts.forEach((count, value) => {
            const offsetStep = 0.015; // Step to offset points
            let offset;
            if (isFirstQuestion) {
                // Always positive offsets for the first question
                offset = offsetStep;
            } else if (isLastQuestion) {
                // Always negative offsets for the last question
                offset = -offsetStep * count;
            } else {
                // Center the scatter around the question number for other questions
                offset = -(Math.floor(count / 2) * offsetStep);
            }
            for (let i = 0; i < count; i++) {
                scatterData.push({
                    question: questionNumber + offset,
                    y: value,
                });
                offset += offsetStep; // Increment the offset for each point
            }
        });
    });
    return scatterData;
}

export const processStdData = (questionData) => {
    return questionData.map((data, index) => {
        const questionNumber = index + 1;
        return { 
            question: questionNumber, 
            std: [data.avgPercentage - data.stdDev, data.avgPercentage + data.stdDev],
            errorBarY: [data.stdDev, data.stdDev],
            low: data.avgPercentage - data.stdDev,
            high: data.avgPercentage + data.stdDev,
            avgPercentage: data.avgPercentage,
            maxPoints: 100,
            missedPoints: 100 - data.avgPercentage
        };
    });
}

export const processQuartilePercentageData = (sentData) => {
    let kwartileData = [];
    
    sentData.forEach((data, index) => {
        const questionNumber = index + 1;

        kwartileData.push({ 
            question: questionNumber, 
            kwartiles: [Math.min(data.q1, data.q3), Math.max(data.q1, data.q3)],
            errorBarY: [data.median - Math.min(data.q1, data.q3), Math.max(data.q1, data.q3) - data.median],
            q1: Math.min(data.q1, data.q3),
            q3: Math.max(data.q1, data.q3),
            median: data.median,
            maxPoints: 100,
            missedPoints: 100 - data.median
        });
    });
    return kwartileData;
}

export const processQuartilePointsData = (sentData) => {
    let heighestPoints = 0;
    let kwartileData = [];
    
    sentData.forEach((data, index) => {
        const questionNumber = index + 1;

        kwartileData.push({ 
            question: questionNumber, 
            kwartiles: [Math.min(data.q1, data.q3), Math.max(data.q1, data.q3)],
            errorBarY: [data.median - Math.min(data.q1, data.q3), Math.max(data.q1, data.q3) - data.median],
            q1: Math.min(data.q1, data.q3),
            q3: Math.max(data.q1, data.q3),
            median: data.median,
            maxPoints: data.maxPoints,
            missedPoints: data.maxPoints - data.median
        });

        if (data.maxPoints > heighestPoints) {
            heighestPoints = data.maxPoints;
        }
    });
    return { kwartileData, heighestPoints };
}

export const processQuartilePointsForQuestions = (newGraphData) => {

    const quartilePointsPerQuestion = newGraphData?.map((questionData, index) => {
        const maxPoints = questionData.maxPoints;
        const studentPoints = questionData.studentPoints;

        // Initialize an array of objects for each point value
        const pointCounts = Array.from({ length: maxPoints + 1 }, (_, index) => ({
            points: index,
            count: 0,
            maxPoints: maxPoints,
        }));

        // Count occurrences of each point value
        studentPoints.forEach(point => {
            const roundedPoint = Math.round(point);

            if (roundedPoint >= 0 && roundedPoint <= maxPoints) {
                pointCounts[roundedPoint].count++;
            }
        });
        return {
            questionNumber: index+1,
            stats: pointCounts
        };
    });

    return quartilePointsPerQuestion;
}
